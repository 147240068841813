import React from "react";
import { TbArrowRight } from "react-icons/tb";

interface ProjectProps {
  name: string;
  description: string;
  award?: {
    icon: React.ReactNode;
    title: string;
    description: string;
    link: string;
  };
  downloadTitle?: string;
  downloadLink?: string;
  homepageLink?: string;
  imageOne: any;
  imageTwo?: any;
  backgroundColor: string;
}

const Project: React.FC<ProjectProps> = (props) => {
  const {
    name,
    description,
    award,
    downloadTitle,
    downloadLink,
    homepageLink,
    imageOne,
    imageTwo,
    backgroundColor
  } = props;

  return (
    <div className="pb-24">
      <div className={`flex flex-col items-center text-black text-center rounded-[60px]`} style={{ height: '600px', backgroundColor: backgroundColor }}>
        {imageTwo ? (
          <div className="flex overflow-hidden gap-24">
            <img src={imageOne} alt="Profile" className="w-[375px] object-cover mt-[70px]" />
            <img src={imageTwo} alt="Profile" className="w-[375px] object-cover mb-[70px]" />
          </div>
        ) : (
          <img src={imageOne} alt="Profile" className="w-[892px] object-cover mt-[70px]" />
        )}
      </div>
      <div className="flex flex-row items-center justify-between gap-6 my-4 w-full">
        <div className="flex flex-col items-start">
          <h2 className="font-sans font-semibold text-2xl text-black">
            {name}
          </h2>
          <p className="font-sans text-lg text-black">
            {description}
          </p>
        </div>
        <div className="flex-1 flex items-center">
          {award && (
            <a
              href={award.link}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-black font-sans font-semibold py-2 px-2 rounded-full flex items-center justify-start border border-black border-opacity-10"
            >
              {award.icon && (
                <span className="mr-2 text-2xl bg-maroBackground rounded-full flex items-center justify-center" style={{ width: '40px', height: '40px' }}>
                  {award.icon}
                </span>
              )}
              <div>
                <h3 className="font-sans font-semibold text-[16px] text-left leading-tight">{award.title}</h3>
                <p className="font-sans font-normal text-[14px] text-left text-maroTextSecondary leading-tight">{award.description}</p>
              </div>
              <TbArrowRight strokeWidth={2.5} className="mx-2 text-lg" ></TbArrowRight>
            </a>
          )}
        </div>
        <div className="flex gap-6 items-center">
          {homepageLink && (
            <a
              href={homepageLink}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-black font-sans font-semibold py-4 px-6 rounded-full flex items-center justify-center border border-black border-opacity-10"
            >
              See details <TbArrowRight strokeWidth={2.5} className="ml-1 text-lg" ></TbArrowRight>
            </a>
          )}
          {downloadLink && (
            <a
              href={downloadLink}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-black text-white font-sans font-semibold py-4 px-6 rounded-full flex items-center justify-center"
            >
              {downloadTitle} <TbArrowRight strokeWidth={2.5} className="ml-1 text-lg" ></TbArrowRight>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Project;
