import React from "react";

interface HeaderProps {
  // Define any props here if needed, for example:
  // title: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  // You can use the props as needed, for example:
  // const { title } = props;

  return (
    <header className="">
      <nav className="container mx-auto py-3 flex justify-between items-center">
        <a className="text-xl font-semibold text-gray-700" href="#">
          maro.
        </a>
        
      </nav>
    </header>
  );
};

export default Header;
