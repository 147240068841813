import React from "react";

const Skills: React.FC = () => {
  const interactionDesignSkills = [
    "UI / UX Design",
    "Rapid Prototyping",
    "Sketch",
    "Figma",
    "Design Thinking",
    "User Research",
  ];

  const softwareEngineeringSkills = [
    "Swift",
    "React",
    "TypeScript",
    "C++",
    "C",
    "Python",
    "Machine Learning",
    "MongoDB",
    "Git",
  ];

  return (
    <section
      id="skills"
      className="text-black py-8 rounded-[30px]"
    >
      <div>
        {/* Interaction Design */}
        <div className="container flex gap-8">
          <div className="w-[600px] flex items-center justify-center">
            <div className="py-4 text-left flex-col h-full">
              <p>
                <span className="font-sans font-bold text-4xl">20+ </span>
                <span className="font-sans font-regular text-3xl text-maroTextSecondary">
                  Web Designs
                </span>
              </p>
              <p className="pt-4">
                <span className="font-sans font-bold text-4xl">10+ </span>
                <span className="font-sans font-regular text-3xl text-maroTextSecondary">
                  Mobile App Designs
                </span>
              </p>
              <div className="pt-24 flex flex-wrap justify-start gap-4">
                {interactionDesignSkills.map((skill, index) => (
                  <span
                    key={index}
                    className="px-4 py-2 border border-black border-opacity-10 rounded-full bg-white"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="w-[600px] pl-4 flex items-center justify-center">
            <div className="text-left">
              <h2 className="py-4 font-sans font-bold text-5xl bg-gradient-to-r from-black to-gray-800 inline-block text-transparent bg-clip-text">
                Interaction Design
              </h2>
              <p className="py-4 font-sans font-medium text-xl text-maroTextSecondary">
                Every product starts as an idea that transforms into a concept,
                evolves into a visual representation, and matures into a
                polished prototype. This progression is a fundamental aspect of
                the lifecycle of any product. With a decade of experience
                utilizing tools such as Sketch and Figma, I have dedicated
                myself to crafting exceptional mobile and web experiences.
                Designs that are both intuitive and aesthetically pleasing
                captivate me, and achieving such perfection is my constant
                pursuit.
              </p>
            </div>
          </div>
        </div>

        {/* Software Engineering */}
        <div className="container flex gap-8">
          <div className="w-[600px] flex items-center justify-center">
            <div className="text-left">
              <h2 className="py-4 font-sans font-bold text-5xl bg-gradient-to-l from-black to-gray-800 inline-block text-transparent bg-clip-text">
                Software Engineering
              </h2>
              <p className="py-4 font-sans font-medium text-xl text-maroTextSecondary">
                My coding journey began at thirteen, sparking a passion that led
                me to study electronics and technical informatics. This early
                interest in technology culminated with the development of iOS
                and Android apps for my diploma thesis, marking the start of
                numerous successful projects. Then I continued to refine my
                skills while pursuing a Bachelor's in Software Engineering and
                Management, dedicating spare time to further app development.
                Recognizing the need for growth, I pursued a Master's at the
                entrepreneurial-focused Technical University of Munich (TUM),
                aiming to both grow as a software developer and as a human
                being.
              </p>
            </div>
          </div>
          <div className="w-[600px] pl-4 flex items-center justify-center">
            <div className="py-4 text-left flex-col h-full">
              <p>
                <span className="font-sans font-bold text-4xl">05+ </span>
                <span className="font-sans font-regular text-3xl text-maroTextSecondary">
                  Web Apps
                </span>
              </p>
              <p className="pt-4">
                <span className="font-sans font-bold text-4xl">10+ </span>
                <span className="font-sans font-regular text-3xl text-maroTextSecondary">
                  iOS Apps
                </span>
              </p>
              <p className="pt-4">
                <span className="font-sans font-bold text-4xl">05+ </span>
                <span className="font-sans font-regular text-3xl text-maroTextSecondary">
                  Smart Contracts
                </span>
              </p>
              <div className="pt-12 flex flex-wrap justify-start gap-4">
                {softwareEngineeringSkills.map((skill, index) => (
                  <span
                    key={index}
                    className="px-4 py-2 border border-black border-opacity-10 rounded-full bg-white"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
