import React from "react";
import profilePic from "../assets/images/max.png";

const About: React.FC = () => {
  return (
    <section id="about" className="py-8">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        <div className="md:w-2/3">
          <h2 className="font-sans text-4xl font-medium">Hey, I'm Max!</h2>
          <p className="mt-4 font-sans text-8xl font-bold">
            Innovating at <br />
            the intersection <br />
            of technology <br />
            and design
          </p>
        </div>
        <div className="md:w-1/3 md:mt-0 flex justify-center md:justify-end">
          <img
            src={profilePic}
            alt="Profile"
            className="w-3/4 max-w-sm mt-0 md:mt-0 self-start"
          />
        </div>
      </div>
    </section>
  );
};

export default About;
