import "./App.css";
import Home from "./pages/Home";

function App() {
  return (
    <div className="bg-maroBackground">
      <div className="fixed inset-0 bg-maroBackground flex flex-col justify-center items-center text-center text-black xl:hidden z-50 ">
        <p className="mt-4 font-sans text-6xl font-bold">
          I believe in <br />
          native mobile <br />
          applications
        </p>
        <h2 className="pt-4 font-sans text-2xl font-normal">
          Please consider using the web version
        </h2>
      </div>
      <Home />
    </div>
  );
}

export default App;
