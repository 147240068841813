import React from "react";
import Header from "../components/Header";
import About from "../components/About";
import Skills from "../components/Skills";
import Projects from "../components/Projects";

const Home: React.FC = () => {
  return (
    <div
      className="min-h-screen min-w-[1100px] mx-auto bg-maroBackground"
      style={{ width: "1100px", overflow: "visible" }}
      // className="min-h-screen w-full px-36 bg-maroBackground"
    >
      <Header />
      <main>
        <section id="about" className="py-12">
          <About />
        </section>
        <section id="skills" className="py-12">
          <Skills />
        </section>
        <section id="projects" className="py-12">
          <Projects />
        </section>
      </main>
    </div>
  );
};

export default Home;
