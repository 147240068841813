import React from "react";
import Project from "./Project";
import simpleVeganOne from "../assets/images/simple-vegan-1.png";
import simpleVeganTwo from "../assets/images/simple-vegan-2.png";
import returnAnywhereOne from "../assets/images/return-anywhere-1.png";
import buildingsTwo from "../assets/images/buildings-2.png";
import returnAnywhereTwo from "../assets/images/return-anywhere-2.png";
import simpleFitOne from "../assets/images/simple-fit-1.png";
import simpleFitTwo from "../assets/images/simple-fit-2.png";
import decentNewsOne from "../assets/images/decent-news-1.png";
import { FaApple } from "react-icons/fa";
import { SiChainlink } from "react-icons/si";


interface ProjectsProps {
  // Define any props here if needed, for example:
  // title: string;
}

const Projects: React.FC<ProjectsProps> = (props) => {
  // You can use the props as needed, for example:
  // const { title } = props;

  return (
    <div className="text-center">
      <h1 className="pt-4 font-sans font-bold text-5xl bg-gradient-to-r from-black to-gray-800 inline-block text-transparent bg-clip-text">
        Selected Projects
      </h1>
      <h2 className="pt-2 pb-16 font-sans font-medium text-xl text-maroTextSecondary">
        More launched and in the making
      </h2>
      {/* Simple Vegan */}
      <Project
        name={"Simple Vegan"}
        description="App Design & Development"
        award={{
          icon: <FaApple />,
          title: "New Apps We Love",
          description: "App Store Feature",
          link: ""
        }}
        downloadTitle="Download in the App Store"
        downloadLink="https://apps.apple.com/us/app/simple-vegan-recipes-plan/id1481847560"
        homepageLink="https://www.simpleveganapp.com/"
        imageOne={simpleVeganOne}
        imageTwo={simpleVeganTwo}
        backgroundColor="#B5D8AE"
      />
      {/* Decent News */}
      <Project
        name={"DecentNews"}
        description="Design & Full Stack"
        downloadTitle="View project"
        downloadLink="https://devfolio.co/projects/decentnews-2c5f"
        homepageLink="https://github.com/Decentralized-News"
        imageOne={decentNewsOne}
        backgroundColor="#B7CEE8"
      award={{
        icon: <SiChainlink />,
        title: "Best Project Using Chainlink",
        description: "ETHMunich Award",
        link: "https://opensea.io/assets/arbitrum/0x020c3A900fdBd33795d709e2b40a1f3510fBe1Fc/75"
      }}
      />
      {/* Return Anywhere */}
      <Project
        name={"Return Anywhere"}
        description="Mobile App Design & Development"
        homepageLink="https://refrastructure.org/en/"
        imageOne={returnAnywhereOne}
        imageTwo={returnAnywhereTwo}
        backgroundColor="#BDDED3"
      />
    </div>
  );
};

export default Projects;
